import axios from 'axios';

export const collectionsService = {
  getAll,
  create,
  remove
};

function getAll(skip=0, limit=100) {
  return axios.get('/api/v1/collections/', {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

function create(name, description, is_public) {
  return axios.post('/api/v1/collections/', {
    withCredentials: true,

    name: name,
    description: description,
    is_public: is_public
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

function remove(id) {
  return axios.delete('/api/v1/collections/' + id, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}
