<script>
// https://stackoverflow.com/questions/62023604/where-to-find-or-how-to-set-htmlwebpackplugin-options-title-in-project-created-w
// https://stackoverflow.com/questions/36612847/how-can-i-bind-the-html-title-content-in-vuejs
export default {
    name: 'vue-title',
    props: ['title'],
    watch: {
        title: {
            immediate: true,
            handler() {
                document.title = this.title;
            }
        }
    },
    render() {
        return null;
    },
}
</script>