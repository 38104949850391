export const timeService = {
    isLeft,
    getDifference,
};

function isLeft(date) {
    date = date + ".000Z";
    const dif = differenceBetweenDates(getCurrentTimeUTC(), date);
    if (dif >= 1000) return true;
    else return false;
}

function getDifference(date) {
    date = date + ".000Z";
    const dif = differenceBetweenDates(getCurrentTimeUTC(), date);
    return formatTimeValues(formatTimeDifference(dif));
}

function formatTimeValues({ days, hours, minutes, seconds }) {
    let result = '';

    if (days > 0) {
        result += days + ' days, ';
    }
    if (hours > 0) {
        result += hours + ' hours, ';
    }
    if (minutes > 0) {
        result += minutes + ' minutes, ';
    }
    if (seconds > 0) {
        result += seconds + ' seconds';
    }
    if (result === '') {
        result += 'Expired';
    }

    return result;
}

function getCurrentTimeUTC() {
    const now = new Date();
    const utcTime = now.toISOString();
    return utcTime;
}

function formatTimeDifference(milliseconds) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const millisecondsPerHour = 60 * 60 * 1000;
    const millisecondsPerMinute = 60 * 1000;

    let days = Math.floor(milliseconds / millisecondsPerDay);
    let hours = Math.floor((milliseconds % millisecondsPerDay) / millisecondsPerHour);
    let minutes = Math.floor((milliseconds % millisecondsPerHour) / millisecondsPerMinute);
    let seconds = Math.floor((milliseconds % millisecondsPerMinute) / 1000);

    // Если разница меньше нуля, устанавливаем все значения в 0
    if (milliseconds < 0) {
        days = hours = minutes = seconds = 0;
    }

    return {
        days: Math.max(days, 0),
        hours: Math.max(hours, 0),
        minutes: Math.max(minutes, 0),
        seconds: Math.max(seconds, 0)
    };
}

function differenceBetweenDates(date1, date2) {
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    return endDate - startDate;
}
