import axios from 'axios';
// import { timeService } from "@/components/time.js";

export const accountsService = {
  login,
  logout,
  getAll,
  getMe,
  register,
  authToken,
  confirmEmail,
  resendEmailCode,
  updatePass,
  askChangePass,
  termSession,
  unsetUserLS,
  getSessions,
};

function login(email, password, remember_me) {
  return axios.get('/api/v1/account/login', {
    withCredentials: true,
    params: {
      remember_me: remember_me
    },
    auth: {
      username: email,
      password: password
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

// function login(email, password) {
//   return axios.get('/api/v1/account/login', {
//     // params: params,
//     withCredentials: true,
//     auth: {
//       username: email,
//       password: password
//     }
//   })
//     .then(response => {
//       console.log("Resp data: " + response);
//       return response;
//     })
//     .catch(
//       response => {
//         return response;
//       },
//       error => {

//         // Если сообщение "Failed to load resource" не генерируется самой библиотекой Axios, а отображается в консоли браузера, то перехватить это сообщение напрямую средствами JavaScript или Axios не получится.
//         // Данное сообщение отображается в консоли разработчика браузера, когда браузер не может загрузить запрашиваемый ресурс (URL или файл) из-за различных проблем, таких как неверный путь, проблемы с сетью или настройками сервера.
//         // Если вам нужно предотвратить отображение этого сообщения в консоли браузера, вы можете использовать специфичные методы или параметры в вашем коде, чтобы избежать ошибок загрузки ресурсов. Например, вы можете проверять наличие ресурса перед его загрузкой или обрабатывать ошибки загрузки с помощью обработчиков событий, предоставляемых браузером.
//         // Однако, перехватить это сообщение напрямую с помощью JavaScript или Axios не представляется возможным, так как оно генерируется браузером во время выполнения запроса.

//         // console.error("My err: " + error.message + " ada: ");
//         throw error;
//       });
// }
// axios.get('/api/v1/account/me')
// .then((res) => {
//   return res.data;
// })
// .catch((error) => {
//   console.log(error);
// });

// return fetch(`/users/authenticate`, requestOptions)
//   .then(handleResponse)
//   .then(user => {
//     // login successful if there's a user in the response
//     if (user) {
//       // store user details and basic auth credentials in local storage
//       // to keep user logged in between page refreshes
//       user.authdata = window.btoa(username + ":" + password);
//       localStorage.setItem("user", JSON.stringify(user));
//     }

//     return user;
//   });


// function logout() {
//   return axios.get('/api/v1/account/logout')
//     .then((res) => {
//       return res.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   // remove user from local storage to log user out
//   // localStorage.removeItem("user");
// }

function logout() {
  unsetUserLS();

  return axios.get('/api/v1/account/logout', {
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });

  // remove user from local storage to log user out
  // localStorage.removeItem("user");
}


function register(email, name, surname, password) {

  const data = {
    email: email,
    name: name,
    surname: surname,
    password: password
  };

  return axios.post('/api/v1/account/register', data, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });

  // remove user from local storage to log user out
  // localStorage.removeItem("user");
}

function setUserLS(_user) {
  localStorage.setItem('user', JSON.stringify(_user));
}

function unsetUserLS() {
  localStorage.removeItem('user');
}

function getUserLS() {
  const userString = localStorage.getItem('user');

  // Если строка из localStorage пуста или null, возвращаем null
  if (!userString || userString === "{}") {
    return null;
  }

  const _user = JSON.parse(userString);
  return _user;
}

function askBackendUser() {
  // это возвращает промис
  return axios.get('/api/v1/account/me', {
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        const status = error.response.status;
        // Обработка ошибки с телом ответа
        throw { error, body, status };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}

async function updateUserLS() {
  // try {
  //   const backendUser = await askBackendUser();
  //   console.log("Response message: ", backendUser);
  // } catch (error) {
  //   if (error.body) {
  //     throw new Error(error.body);
  //   } else {
  //     throw new Error(error.message);
  //   }
  // }

  const backendUser = await askBackendUser();
  unsetUserLS();
  setUserLS(backendUser);
}

async function getMe(forced) {
  forced = (typeof forced !== 'undefined') ? forced : false;

  if (forced) {
    await updateUserLS();
  }

  let storedUser = getUserLS();

  if (storedUser === null) {
    await updateUserLS();
    storedUser = getUserLS();
  }

  // if (timeService.isLeft(setUserLS.expires_at)) {
  //   updateUserLS();
  // }

  return storedUser;
}

function getAll(skip = 0, limit = 100) {
  return axios.get('/api/v1/users', {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function authToken(code) {
  return axios.get('/api/v1/account/auth/google', {
    params: {
      code: code
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function confirmEmail(code) {
  unsetUserLS();

  return axios.get('/api/v1/account/confirm', {
    params: {
      code: code
    },
    withCredentials: true
  })
    .then(response => {
      if (response.status == 201)
        return true;
      else
        return false;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function resendEmailCode(just_check) {
  return axios.get('/api/v1/account/resendemailcode', {
    params: {
      just_check: just_check
    },
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function updatePass(email, code, password) {
  const queryParams = {
    email: email,
    code: code,
  };

  const postData = {
    password: password
  };

  return axios.post('/api/v1/account/change_pass', postData, {
    params: queryParams,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}


function askChangePass(email) {
  return axios.get('/api/v1/account/ask_change_pass', {
    params: {
      email: email
    },
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function termSession(session_id) {
  unsetUserLS();

  const postData = {
    id: session_id
  };

  return axios.post('/api/v1/account/session', postData, {
    withCredentials: true,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        throw { error, body };
      } else {
        throw error;
      }
    });
}


function getSessions() {
  return axios.get('/api/v1/account/sessions', {
    params: {
      show_expired: true,
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}
