<template>
  <MyProfile @userDataLoaded="handleUserDataLoaded" />
  <div v-if="user">
    <h1>Home</h1>
    <div v-if="error" class="alert alert-danger mb-3">
      {{ error }}
      <span aria-label="Close" @click="() => error = ''" style="cursor: pointer; float: right;">&times;</span>
    </div>

    <p>Welcome to your personal space!</p>

    <h2 class="mt-3">User information</h2>

    <p class="m-0"><b>Email:</b> {{ user.email }}</p>
    <p class="m-0"><b>Name:</b> {{ user.name }}</p>
    <p class="m-0"><b>Surname:</b> {{ user.surname }}</p>
    <p class="m-0"><b>Url user avatar:</b> {{ user.url_avatar }}</p>
    <p class="m-0"><b>Is blocked:</b> {{ user.is_blocked }}</p>
    <p class="m-0"><b>Is email verified:</b> {{ user.email_is_verified }}</p>
    <p class="m-0"><b>Created at:</b> {{ user.created_at }}</p>
    <p class="m-0"><b>Update on:</b> {{ user.update_on }}</p>

    <h2 class="mt-3">Permissions</h2>
    <ul class="flex-column mb-auto">
      <li v-for="perm in user.perms" :key="perm.id">
        {{ perm }}
      </li>
    </ul>

    <h2 class="mt-3">Sessions</h2>

    <button class="btn btn-primary py-2" @click="toggleShowSessions">{{ messageShowSessions }}</button>

    <div class="m-0 border-0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">User Agent</th>
            <th scope="col">Issued (UTC)</th>
            <th scope="col">Expires in</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="session in reversedSessions" :key="session.issued_at">
            <td>{{ session.user_agent }}</td>
            <td>{{ session.issued_at }}</td>
            <td>{{ timeService.getDifference(session.expires_at) }}</td>
            <td><button class="btn btn-primary" v-bind:disabled="!timeService.isLeft(session.expires_at)" @click="termSession(session.id)">Terminate</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MyProfile from '@/components/MyProfile.vue'
import { accountsService } from "@/components/account.js";
import { timeService } from "@/components/time.js";

export default {
  name: "MyHome",
  components: {
    MyProfile
  },

  data() {
    return {
      error: '',

      timeService: timeService,
      user: null,
      sessions: [],
      isShowAllSessions: false,
      messageShowSessions: "Show All Sessions",
    }
  },
  // props: ['navbarVariable'],
  watch: {
  },
  computed: {
    reversedSessions: function() {
      return this.filteredSessions.slice().reverse(); // Инвертируем массив сеансов
    },
    filteredSessions: function() {
      if (this.isShowAllSessions)
        return this.sessions;
      else
        return this.sessions.filter(session => timeService.isLeft(session.expires_at));
    }
  },
  methods: {
    toggleShowSessions() {
      if (this.isShowAllSessions) {
        this.isShowAllSessions = false;
        this.messageShowSessions = "Show All Sessions";
      }
      else {
        this.isShowAllSessions = true;
        this.messageShowSessions = "Show Active Sessions";
      }
    },
    removeSessionById(id) {
      this.sessions = this.sessions.filter(session => session.id !== id);
    },
    termSession(id) {
      accountsService.termSession(id)
        .then(data => {
          if (data) {
            this.removeSessionById(id);
          }
          // console.log("Response message:", data);
        })
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              this.error = error.message;
            }

            // if (this.error === "You are not logged") {
            //   this.error = '';
            // }
          }
        );
    },
    getSessions() {
      accountsService.getSessions()
        .then(data => {
          if (data) {
            this.sessions = data;
          }
          console.log("Response message:", data);
        })
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              this.error = error.message;
            }
          }
        );
    },
    handleUserDataLoaded(userProxy) {
      this.user = userProxy;
    },

  },
  mounted() {
    this.getSessions();
    // console.log(this.navbarVariable);
  },
  created() {
  }
}
</script>
