import axios from 'axios';

export const permsService = {
  getAll
};

function getAll(skip=0, limit=100) {
  return axios.get('/api/v1/perms/', {
    params: {
      skip: skip,
      limit: limit
    },
    withCredentials: true
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const body = error.response.data;
        // Обработка ошибки с телом ответа
        throw { error, body };
      } else {
        // Обработка ошибки без тела ответа
        throw error;
      }
    });
}
