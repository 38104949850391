import { createRouter, createWebHistory } from 'vue-router'
import MainView from '@/views/Main.vue'
import HomeView from '@/views/Home.vue'
import RegistrationView from '@/views/Registration.vue'
import AdminUsersView from '@/views/AdminUsers.vue'
import AdminPermsView from '@/views/AdminPerms.vue'
import CollectionsView from '@/views/Collections.vue'
import CustomerWorkspaceView from '@/views/CustomerWorkspace.vue'
import GoogleCallbackView from '@/views/GoogleCallback.vue'
import EmailCallbackView from '@/views/EmailCallback.vue'
import NotFoundView from '@/views/NotFound.vue'
import ResetPassView from '@/views/ResetPass.vue'
import AskChangePassView from '@/views/AskChangePass.vue'

const routes = [
  {
    path: '/:catchAll(.*)',
    component: NotFoundView,
  },
  {
    name: 'main',
    path: '/',
    component: MainView
  },
  {
    name: 'signup',
    path: '/signup',
    component: RegistrationView
  },
  {
    name: 'callback',
    path: '/callback',
    component: GoogleCallbackView
  },
  {
    name: 'verify',
    path: '/verify',
    component: EmailCallbackView
  },
  {
    name: 'login',
    path: '/login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  },
  {
    name: "forgotpass",
    path: "/forgotpass",
    component: AskChangePassView
  },
  {
    name: "changepass",
    path: "/changepass",
    component: ResetPassView
  },
  {
    name: 'customerworkspace',
    path: '/home',
    redirect: '/home/profile',
    component: CustomerWorkspaceView,
    children: [
      {
        name: 'profile',
        path: 'profile',
        component: HomeView
      },
      {
        name: 'users',
        path: '/admin/users',
        component: AdminUsersView
      },
      {
        name: 'perms',
        path: '/admin/perms',
        component: AdminPermsView
      },
      {
        name: 'collections',
        path: 'collections',
        component: CollectionsView
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

export default router
