<template>
  <form @submit.prevent="signInWithGoogle">
    <button class="btn btn-danger w-100 my-3 py-2" :disabled="!uri">
      <i class="fab fa-google"></i>{{ button_text }}
    </button>
  </form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'signInWithGoogle',
  data() {
    return {
      uri: "",
      error: "",
    };
  },
  props: {
    button_text: {
      type: String,
      required: true
    }
  },
  methods: {
    getGoogleUri() {
      axios.get('/api/v1/account/login/google')
        .then(response => {
          this.uri = response.data.url;
        })
        .catch(error => {
          if (error.response && error.response.data) {
            const body = error.response.data;
            this.error = body;
          } else {
            this.error = error;
          }

          if (error.response.status && error.response.status === 502) {
            this.error = "Backend is not ready";
          }

          console.log(this.error);
        });
    },
    signInWithGoogle() {
      if (this.uri != "") window.location.href = this.uri;
    },
  },
  created() {
  },
  mounted() {
    this.getGoogleUri();
  }
};
</script>
