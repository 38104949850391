export const gravatar = {
    getGravatarURL,
    getDefaultImage
};

import md5 from 'md5';

function getGravatarURL(email) {
    const address = String(email).trim().toLowerCase();
    const hash = md5(address);
    return `https://www.gravatar.com/avatar/${hash}`;
}

function getDefaultImage(firstName, lastName) {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const context = canvas.getContext('2d');

    // Set color and background style for the default image
    context.fillStyle = '#f0f0f0';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw initials on the canvas
    context.fillStyle = '#000000';
    context.font = '24px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    const initials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    context.fillText(initials, canvas.width / 2, canvas.height / 2);

    // Get image data in base64 format
    const base64Image = canvas.toDataURL('image/png');
    return base64Image;
}