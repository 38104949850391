<template>
  <titleComponent :title="title"></titleComponent>
  <router-view />
</template>

<script>
import titleComponent from '@/components/title.vue';

export default {
  components: {
    titleComponent
  },
  data() {
    return {
      title: 'Collections Keeper'
    }
  }
}
</script>

