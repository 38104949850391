import { createApp } from "vue";

import App from './App.vue';
const app = createApp(App);

import './plugins/axios'

import router from './router';
app.use(router);

import i18n from './plugins/i18n';
app.use(i18n);

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

app.mount("#app");
