<template>
  <main class="form-signup w-100 m-auto">

    <div class="d-flex justify-content-between mb-3">
      <a href="/">
        <img class="mb-3" src="/svg/folders-folder-svgrepo-com.svg" alt="" width="72" height="57"
          style="margin-left:-5px;">
      </a>
      <changeLang add_sel_classes="w-auto mr-auto"/>
    </div>

    <div class="d-flex justify-content-between mb-3 align-items-end">
      <h1 class="h3 fw-normal">{{ $t("resetpass.title") }}</h1>
    </div>


    <div v-if="user_is_logged">
      <p>{{ $t("resetpass.when_user_is_logged") }}</p>

      <div>
        <a href="#" @click="goCover">{{ $t("notfound.home_link") }}</a>
      </div>
    </div>

    <div v-if="!user_is_logged && !email_and_code_sent">
      <p>{{ $t("resetpass.when_email_or_code_were_not_sent") }}</p>
    </div>

    <div v-if="!user_is_logged && email_and_code_sent">
      <form @submit.prevent="handleSubmit">
        <div class="form-floating">
          <input type="email" v-model="email" name="email" class="form-control" id="email" :placeholder="email" disabled="true"/>
          <label for="email">{{ $t("resetpass.enter_email") }}</label>
        </div>
        <div class="form-floating">
          <input type="password" class="form-control" v-model="password1" name="password1" id="password1"
            placeholder="Password" :class="{ 'is-invalid': submitted && !password1 }" />
          <label for="password1" v-show="!submitted || password1">{{ $t("resetpass.enter_password") }}</label>
          <label v-show="submitted && !password1" class="invalid-feedback">{{ $t("resetpass.notice_password_required") }}</label>
        </div>
        <div class="form-floating">
          <input type="password" class="form-control" v-model="password2" name="password2" id="password2"
            placeholder="Password" :class="{ 'is-invalid': submitted && !password2 }" />
          <label for="password2" v-show="!submitted || password2">{{ $t("resetpass.notice_repeat_password") }}</label>
          <label v-show="submitted && !password2" class="invalid-feedback">{{ $t("resetpass.notice_password_required") }}</label>
        </div>

        <div v-if="passwordMismatch" class="text-danger mb-3">
          {{ $t("resetpass.notice_passwords_dont_match") }}
        </div>

        <button class="btn btn-primary w-100 py-2" type="submit" v-bind:disabled="loading">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ $t("resetpass.button_set_new_pass") }}
        </button>
      </form>
    </div>

    <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
    <p class="mt-5 mb-3 text-body-secondary">{{ copyright }}</p>
  </main>
</template>

<script>
// import router from "@/router";
import { accountsService } from "@/components/account.js";
import { copyright } from "@/components/copyright";
import changeLang from "@/components/ChangeLang.vue";

// https://renatello.com/vue-js-body-class/
export default {
  name: 'ResetPass',
  components: {
    changeLang
  },
  data() {
    return {
      user: '',
      email: '',
      code: '',
      password1: '',
      password2: '',
      passwordMismatch: false,
      submitted: false,
      loading: false,
      copyright: '',
      error: '',
      user_is_logged: false,
      email_and_code_sent: false,
    };
  },
  methods: {
    goCover() {
      this.$router.push('/');
    },
    signout() {
      accountsService.logout();
    },
    getme() {
      accountsService.getMe()
        .then(data => {
          console.log("Response message:", data);
          this.user = data;
          this.checkIfUserLogged(data);
        })
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              this.error = error.message;
            }

            if (this.error === "You are not logged") {
              this.error = '';
            }
          }
        );
    },
    checkIfUserLogged(user) {
      if (user) {
        this.user_is_logged = true;
      }
    },
    getCopyright() {
      this.copyright = copyright.getCopyright();
    },
    changeParams() {
      // check that code was set
      const email = this.$route.query.email;
      const code = this.$route.query.code;

      if (email != null && code != null) {
        this.email_and_code_sent = true;
        this.email = email;
        this.code = code;
      } else {
        this.email_and_code_sent = false;
      }

    },
    // handleSubmit(e) {
    handleSubmit() {
      this.submitted = true;
      const { password1, password2 } = this;

      if (this.password1.length === 0) return;
      if (this.password2.length === 0) return;

      if (this.password1 !== this.password2) {
        this.passwordMismatch = true;
        return;
      } else {
        this.passwordMismatch = false;
      }

      if (this.password1.length < 8) {
        this.error = this.$i18n.t("resetpass.error_pass_too_small");
        return;
      } else {
        this.error = '';
      }

      // stop here if form is invalid
      if (!(password1 && password2)) {
        return;
      }

      this.loading = true;
      accountsService.updatePass(this.email, this.code, password1)
        .then(data => {
          if (data === true) {
            this.$router.push('/login');
          } else {
            console.error("Unexpected response format:", data);
            this.error = this.$i18n.t("resetpass.error_from_server");
            this.loading = false;
          }
        })
        .catch(
          error => {
            if (error.body) {
              this.error = error.body;
            } else {
              // Обработка ошибки без тела ответа
              // console.error("Error:", error.message);
              this.error = error.message;
            }

            if (this.error === "Too Many Requests") {
              this.error = this.$i18n.t("resetpass.error_too_many");
            }

            if (this.error === "You are already logged") {
              this.$router.push('/home');
            }

            this.loading = false;
          }
        );
    }
  },
  created() {
    this.getme();
    this.getCopyright();
    this.changeParams();
    // this.signout();
    // this.signin();
  },
  mounted() {
    document.body.classList.add('d-flex', 'justify-content-center', 'align-items-center')
  },
  unmounted() {
    document.body.classList.remove('d-flex', 'justify-content-center', 'align-items-center')
  }
}
</script>
<style>
/* signup */
html,
body {
  height: 100%;
}

.form-signup {
  max-width: 330px;
  padding: 1rem;
}

.form-signup .form-floating:focus-within {
  z-index: 2;
}

.form-signup input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signup input[name="password1"] {
  margin-bottom: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signup input[name="password2"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* settings */
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}
</style>